<template>
    <div class="sup_content Scan-list">
        <div class="sup_page_heading">
            <h2>Scan Settings</h2>
            <!-- <Button v-if="createScan" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add Scan'" @click="scanModal.addScan = true" /> -->
        </div>
        <div class="sup_inner_content">
            <!--- Show Scan Table Start --->
            <DataTable class="p-datatable" :paginator="true" v-if="allScanSettingsData.data" :value="allScanSettingsData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allScanSettingsData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading customers data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button v-if="scanDeactiveList" :label="allScanSettingsData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allScanSettingsData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allScanSettingsData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllScan(allScanSettingsData.activityStatus, 'scanStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allScanSettingsData.search" placeholder="Search Scan" @keyup.enter="fetchAllScan(allScanSettingsData.activityStatus, 'search')"/>
                            </span>
                            <Button v-if="allScanSettingsData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllScan(allScanSettingsData.activityStatus, 'resetFilters')"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Settings found.
                </template>
                <Column field="websiteUrl" header="Website Url" headerStyle="width: 12rem" :sortable="true"></Column>

                <Column field="user" header="Customer" headerStyle="width: 17rem" :sortable="true">
                    <template #body="slotProps" v-if="allScanSettingsData.data">
                        {{slotProps.data.User.name}}<br>{{slotProps.data.User.email}}
                    </template>
                </Column>

                <Column field="weeklyScanCount" header="Weekly Limit" :sortable="true"></Column>

                <Column field="weeklyScanCountRemaining" header="Weekly Remain Counts" :sortable="true"></Column>

                <Column field="isRealTimeScanEnabled" header="Auto Scan" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.isRealTimeScanEnabled ? "Yes" : "No"}}
                        <!-- <label v-if="slotProps.data != null" :for="slotProps.data.uuid" :class="['sup_status_switch', slotProps.data.isRealTimeScanEnabled ? 'sup_status_active' : 'sup_status_inactive', slotProps.data.deletedAt !== null ? 'sup_status_switch_disable' : '']" >
                            <input v-if="slotProps.data.isRealTimeScanEnabled" type="checkbox" role="switch" :id="slotProps.data.uuid" checked>
                            <input v-else type="checkbox" role="switch" :id="slotProps.data.uuid">
                            <span class="sup_switch_label" data-active="Yes" data-inactive="No" @click="switchisRealTimeScanEnabled(slotProps.data.uuid, slotProps.data.isRealTimeScanEnabled, $event)"></span>
                            <span class="sup_switch_handle"></span>
                        </label> -->
                    </template>
                </Column>

                <Column field="realtimeScanCount" header="Auto Scan Count" :sortable="true"></Column>
                
                <Column field="realtimeScanCountRemaining" header="Auto Scans Remaining" :sortable="true"></Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="viewEdit(slotProps.data, 'editScanSetting')" v-tooltip="'Edit'" />
                        <Button v-if="scanDeactive ? slotProps.data.deletedAt === null : false" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="deleteRestoreModal(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-success p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore'" @click="deleteRestoreModal(slotProps.data, 'restore')" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Scan Table End --->
        </div>
        <!--- Add Scan Modal Start --->
        <Dialog header="Add New Scan Setting" v-model:visible="scanModal.addScan" :modal="true" :style="{width: '600px'}" @hide="closeModal()">
            <form class="sup_customer_add" @submit.prevent="createScan()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="u_role">Customer<span class="text-danger">*</span></label>
                        <Dropdown v-model="addEditScan.customerEmail" :options="allCustomerData.data" id="u_role" optionLabel="email" optionValue="uuid" placeholder="Select Customer" @change="fetchWebsiteList()" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.customerEmailEmpty">Please select customer.</small>
                    </div>
                    <div class="p-field p-col-12">
                        <label for="u_url">Website Url<span class="text-danger">*</span></label>
                        <Dropdown :v-for="allWebsiteData.data" v-model="addEditScan.websiteUrl" :options="allWebsiteData.data" id="u_url" optionLabel="siteUrl" optionValue="siteUrl" placeholder="Select Website Url" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.websiteUrlEmpty">Please select website url</small>
                    </div>
                    <div class="p-field p-col-12">
                        <label for="u_wsc">Weekly Limit<span class="text-danger">*</span></label>
                        <Dropdown v-model="allWeeklyScanCountData" :options="weeklyScanCountData" id="u_wsc" optionLabel="name" optionValue="key" placeholder="Select Weekly Limit" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.weeklyScanCountEmpty">Please select weekly limit</small>
                    </div>
                    <div class="p-field p-col-12">
                        <label for="u_rse">Is Auto Scan Enabled<span class="text-danger">*</span></label>
                        <Dropdown v-model="allRealtimeScanEnabled" :options="realtimeScanEnabled" id="u_rse" optionLabel="name" optionValue="key" placeholder="Select Auto Scan" @change="autoScanEnabled(allRealtimeScanEnabled)"/>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.realtimescanEnabledEmpty">Please select auto scan enabled or not</small>
                    </div>
                    <div class="p-field p-col-12" v-if="autoScanLimitEnabled.data">
                        <label for="u_asl">Auto Scan Limit<span class="text-danger">*</span></label>
                        <Dropdown v-model="allRealtimescanCount" :options="realtimescanCount" id="u_asl" optionLabel="name" optionValue="key" placeholder="Select Auto Scan limit" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.realtimeScanCountEmpty">Selact auto scan limit</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allScanSettingsData.addStatus === 200 ? 'success' : 'error']" v-if="allScanSettingsData.addStatus">{{allScanSettingsData.addMessage}}</div>
            </form>
        </Dialog>
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="dltRestoreModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dltRestoreModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteScanSetting(dltRestoreModal.modaldata.data, dltRestoreModal.modaldata.load), dltRestoreModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
        <!--- Edit Scan Modal Start --->
        <Dialog v-model:visible="scanModal.editScan" :modal="true" :style="{width: '600px'}" @hide="closeModal()">
            <template #header>
                <span class="p-dialog-title">Edit Scan Setting</span>
            </template>
            <form class="sup_user_edit" @submit.prevent="updateScanSetting(scanModal.scanModalData.uuid)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12" >
                        <label for="u_role">Customer</label>
                        <InputText v-model="addEditScan.customerEmail" id="u_role" placeholder="Select Customer" type="text" disabled />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="u_url">Website Url</label>
                        <InputText v-model="addEditScan.websiteUrl" id="u_url" placeholder="Select Website Url" type="text" disabled />
                    </div>
                    <div class="p-field p-col-12">
                        <label for="u_wsc">Weekly Limit<span class="text-danger">*</span></label>
                        <Dropdown v-model="allWeeklyScanCountData" :options="weeklyScanCountData" id="u_wsc" optionLabel="name" optionValue="key" placeholder="Select Weekly Limit" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.weeklyScanCountEmpty">Please select weekly limit</small>
                    </div>
                    <div class="p-field p-col-12">
                        <label for="u_rse">Is Auto Scan Enabled<span class="text-danger">*</span></label>
                        <Dropdown v-model="allRealtimeScanEnabled" :options="realtimeScanEnabled" id="u_rse" optionLabel="name" optionValue="key" placeholder="Select Auto Scan" @change="autoScanLimitData(allRealtimeScanEnabled)"/>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.realtimescanEnabledEmpty">Please select auto scan enabled or not</small>
                    </div>
                    <div class="p-field p-col-12" v-if="allRealtimeScanEnabled==1">
                        <label for="u_">Auto Scan Limit<span class="text-danger">*</span></label>
                        <Dropdown v-model="allRealtimescanCount" :options="realtimescanCount" id="u_role" optionLabel="name" optionValue="key" placeholder="Select Auto Scan limit" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.realtimeScanCountEmpty">Select auto scan limit</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Update" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allScanSettingsData.editStatus === 200 ? 'success' : 'error']" v-if="allScanSettingsData.editStatus">{{allScanSettingsData.editMessage}}</div >
            </form>
        </Dialog>
        <!--- Edit Customer Modal End --->
        <!-- Toast to show notification of events start -->
        <Toast position="top-right" />
        <!-- Toast to show notification of events end -->
    </div>
</template>

<script>
import { getAllScanSetting, allCustomer,updateAllScanSetting, updateAutoScanSetting,allWebsite,getAllScanSettingById, deleteScanSettingDetails } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import { formatDate, usFormatDate } from '../../../../helper/utility/utility';
import axios from "axios";
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import { reactive, onBeforeMount, ref, computed } from 'vue';

export default {
    name: 'accessabilityScanManagement',
    setup(){
        //Vuex store data call fucntion
        const store = useStore();
        //Route call
        const route = useRoute();
        console.log(route.params.id);
        //All modal open/close handling state
        const scanModal = reactive({
            addScan: false,
            editScan: false,
            scanModalData : ''
        });
        const dltRestoreModal = reactive({
            modalState: false,
            modaldata: '',
            uuid: ''
        })
        //Customer initial data storing state
        const allScanSettingsData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'all',
            search: null,
            dateRange: null,
            addStatus : null,
            addMessage : null,
            editStatus : null,
            editMessage : null
        });
        //Form validation error state
        const addEditvalidationError = reactive({
            customerEmailEmpty: false,
            websiteUrlEmpty: false,
            weeklyScanCountEmpty: false,
            realtimescanEnabledEmpty: false,
            realtimeScanCountEmpty: false,
        });
        const allCustomerData = reactive({
            data: null,
            status: null,
        });
        const allWebsiteData = reactive({
            data: null,
            status: null,
        });
        const realtimeScanEnabled = ref([
            {name: 'Yes', key: '1'}, 
            {name: 'No', key: '0'}
        ]);
        const allRealtimeScanEnabled = ref({});
        const realtimescanCount = ref([
            {name: '1', key: 1}, 
            {name: '2', key: 2},
            {name: '3', key: 3},
        ]);
        const allRealtimescanCount = ref({});
        const weeklyScanCountData = ref([
            {name: '1', key: 1}, 
            {name: '2', key: 2},
            {name: '3', key: 3},
        ]);
        const allWeeklyScanCountData = ref({});

        const addEditScan = reactive ({
            customerName : '',
            customerEmail : '',
            websiteUrl :'',
        });
        let autoScanLimitEnabled = reactive({
            data: null
        });
        let autoScanLimit = reactive({
            data: null
        });
        const autoScanLimitData = async(value) => {
            if (value === "1") {
                autoScanLimit.data = true
            } else {
                autoScanLimit.data = false
            }
        }
        const autoScanEnabled = async(value) => {
            if (value === "1") {
                autoScanLimitEnabled.data = true;
            } else {
                autoScanLimitEnabled.data = false
            }
        }

        //User privilages role, UUID and actions store
        const pagePrivilages = reactive({
            userRole: '',
            uuid: '',
            actions: ''
        });

        const scanDeactive = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.scanmanagementDeactivate);
        const scanDeactiveList = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.scanmanagementDeactivateList);
        
         /**
         * Self calling fucntion on compement initiation
         */
        (async () => {
            let { userRole, menuActions } = await currentMenuUuid('ScanSettingList');
            pagePrivilages.userRole = userRole;
            pagePrivilages.actions = menuActions;
        })();
        
        /**
         * All add/edit form validation
         */
        const fromValidation = () => {
            if (!addEditScan.customerEmail) {
                addEditvalidationError.customerEmailEmpty = true;
            } else {
                addEditvalidationError.customerEmailEmpty = false;
            }

            if (!addEditScan.websiteUrl) {
                addEditvalidationError.websiteUrlEmpty = true;
            } else {
                addEditvalidationError.websiteUrlEmpty = false;
            }
            if (!allWeeklyScanCountData.value) {
                addEditvalidationError.weeklyScanCountEmpty = true;
            } else {
                addEditvalidationError.weeklyScanCountEmpty = false;
            }

            if (!allRealtimeScanEnabled.value) {
                addEditvalidationError.realtimescanEnabledEmpty = true;
            } else {
                addEditvalidationError.realtimescanEnabledEmpty = false;
            }

            if (!allRealtimescanCount.value) {
                addEditvalidationError.realtimeScanCountEmpty = true;
            } else {
                addEditvalidationError.realtimeScanCountEmpty = false;
            }

            if (!addEditvalidationError.customerEmailEmpty && !addEditvalidationError.websiteUrlEmpty && !addEditvalidationError.weeklyScanCountEmpty && !addEditvalidationError.realtimescanEnabledEmpty && !addEditvalidationError.realtimeScanCountEmpty) {
                return true;
            } else {
                return false;
            }
        }
        //Customer list for new customer search dropdown
        const fetchCustomerList = async() => {
            const response = await axios.get( allCustomer, {
                params: {
                    status: 'active',
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                allCustomerData.data = response.data.data.users;
            }
        }
        const fetchWebsiteList = async() => {
            const response = await axios.get( allWebsite, {
                params: {
                    userId : addEditScan.customerEmail
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                allWebsiteData.data = response.data.data.websites;
            }
        }
        const fetchAllScan = async (scanStatus, load) => {
            let date = null;
            //Toggle Customer status to fetch active/deactive role
            if(load === 'scanStatus'){
                allScanSettingsData.activityStatus = scanStatus === 'active' ? 'de-active' : 'active' ;
            }

            //To clear search and filter then fetch all data
            if(load === 'resetFilters'){
                allScanSettingsData.search = null;
                allScanSettingsData.dateRange = null;
            }

            //Filter date wise code
            if (load === 'dateRange') {
                date = formatDate(allScanSettingsData.dateRange[0]) + ' ' + formatDate(allScanSettingsData.dateRange[1]);
            }

            //API call start
            try{
                allScanSettingsData.loading = !allScanSettingsData.loading;
                const response = await axios.get( getAllScanSetting, {
                    params: {
                        status: allScanSettingsData.activityStatus,
                        search: allScanSettingsData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                    allScanSettingsData.data = response.data.data.scanSetting;
                    allScanSettingsData.total = response.data.data.total;
                    allScanSettingsData.loading = !allScanSettingsData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }
        const updateScanSetting = async (uuid) => {
            try{
                const options = {
                    method: 'put',
                    url: updateAllScanSetting,
                    params: {
                        uuid: uuid
                    },
                    data: JSON.stringify({
                        weeklyScanCount: allWeeklyScanCountData.value,
                        isRealTimeScanEnabled: allRealtimeScanEnabled.value,
                        realtimeScanCount: allRealtimescanCount.value
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                let response = await axios(options);
                allScanSettingsData.editStatus = response.data.status;
                allScanSettingsData.editMessage = response.data.message;
                fetchAllScan();
            } catch(err){
                allScanSettingsData.editStatus = err.response.data.status;
                allScanSettingsData.editMessage = err.response.data.message;
            }
        }
        /**
         * On view and edit customer UI content update by respective customer data
         */
        const viewEdit = async (slotData, load) => {
            const response = await axios.get( getAllScanSettingById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                
                if (load === 'editScanSetting') { //On edit every input data updation
                    scanModal.editScan = true;
                    scanModal.scanModalData = slotData;
                    addEditScan.customerEmail = response.data.data.customer,
                    addEditScan.websiteUrl = response.data.data.websiteUrl,
                    allWeeklyScanCountData.value = response.data.data.weeklyScanCount;
                    allRealtimeScanEnabled.value = response.data.data.isRealTimeScanEnabled === true ? '1' : '0';
                    allRealtimescanCount.value = response.data.data.realtimeScanCount;
                }                
            }
        }
        /**
         * On component get mounted require functions call
         */
        onBeforeMount( async() => {
            fetchAllScan();
            fetchCustomerList()
        });
        /**
         * Website is free status change function
         * @param {UUID} is for unique id of the site
         * @param {isfree} is for the current status of the site
         */
        const switchisRealTimeScanEnabled = async (uuid, isRealTimeScanEnabled) => {

            //API call start
            try{
                const options = {
                    method: 'put',
                    url: updateAutoScanSetting,
                    params: {
                        uuid: uuid
                    },
                    data: JSON.stringify({
                        isRealTimeScanEnabled: isRealTimeScanEnabled === false ? true : false,
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                let response = await axios(options);
                console.log(response);
                fetchAllScan();
            } catch(err){
                console.log('err', err);
            }
        }
        /**
         * Fucntion is for add new customer and data pass to api
         * After API success calling customer list again by fetchAllScan()
         * And reseting form value
         */
        const createScan = async () => {
            let isValidate = fromValidation();
            if (isValidate) {
                try{
                    const response = await axios.post( getAllScanSetting,{
                        // websiteUrl: addEditScan.websiteUrl
                        customerUuid: addEditScan.customerEmail,
                        siteUrl: addEditScan.websiteUrl,
                        weeklyScanCount: allWeeklyScanCountData.value,
                        isRealTimeScanEnabled: allRealtimeScanEnabled.value,
                        realtimeScanCount: allRealtimescanCount.value
                    },{
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    if(response.status === 200) {
                        allScanSettingsData.addStatus = response.data.status;
                        allScanSettingsData.addMessage = response.data.data;
                        fetchAllScan();
                    }
                } catch(err){
                    allScanSettingsData.addStatus = err.response.data.status;
                    allScanSettingsData.addMessage = err.response.data.message;
                }
            }
        }

        /**
         * Delete Scan
         * On success fetch all cutomer data again to update list
         */
        const deleteScanSetting = async(sloteData, type) => {
            try {
                const options = {
                        method: type === 'delete' ? 'delete' : 'patch',
                        url: deleteScanSettingDetails,
                        params: {
                            uuid : sloteData.uuid
                        },
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                    };
                let response = await axios(options);
                console.log(response);
                fetchAllScan('active');
            } catch(err){
                console.log(err);
            }
        }
        //All modal after close function call
        const closeModal = () => {
            allScanSettingsData.addStatus= '',
            allScanSettingsData.addMessage= '',
            allScanSettingsData.editStatus = '';
            allScanSettingsData.editMessage = '';
        }
        const deleteRestoreModal = (sloteData, load) => {
            dltRestoreModal.modalState = true;
            dltRestoreModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        //Returing variables to template
        return{
            allScanSettingsData,
            allCustomerData,
            fetchCustomerList,
            addEditvalidationError,
            weeklyScanCountData,
            allWeeklyScanCountData,
            realtimeScanEnabled,
            allRealtimeScanEnabled,
            allRealtimescanCount,
            realtimescanCount,
            fetchWebsiteList,
            allWebsiteData,
            autoScanEnabled,
            scanDeactiveList,
            scanDeactive,
            autoScanLimitEnabled,
            fromValidation,
            updateScanSetting,
            autoScanLimitData,
            scanModal,
            deleteScanSetting,
            autoScanLimit,
            viewEdit,
            addEditScan,
            createScan,
            closeModal,
            fetchAllScan,
            switchisRealTimeScanEnabled,
            usFormatDate,
            dltRestoreModal,
            deleteRestoreModal
        }
    }
}
</script>

<style lang="scss">
.p-tieredmenu{
    width: 141px;
}
.sub_customer_view{
    .p-dialog-header-icons{
        display: none;
    }
}
</style>
<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/customer/CustomerList";
</style>